import React from "react";

function GitcoinInstance() {
  return (
    <iframe
      title="Gitcoin"
      src="https://consensyshealth.com/covid-19/"
      style={{
        height: `100%`,
        width: `100%`,
        border: "0px",
      }}
    ></iframe>
  );
}

export default GitcoinInstance;
