import React from "react";

function CalendarInstance() {
  return (
    <iframe
      src="https://ethturin.com/agendaiframe"
      width="100%"
      height="100%"
      frameBorder="0"
      title="calendar"
    ></iframe>
  );
}

export default CalendarInstance;
