import React from 'react';

function AboutInstance() {
	return (
		<iframe
			title='About NONCON'
			src='https://noncon.org/about'
			style={{
				height: `100%`,
				width: `100%`,
				border: '0px'
			}}></iframe>
	);
}

export default AboutInstance;
