import React from 'react';

function DonateInstance() {
	return (
		<iframe
			title='About NONCON'
			src='https://noncon.org/donate'
			style={{
				height: `100%`,
				width: `100%`,
				border: '0px'
			}}></iframe>
	);
}

export default DonateInstance;
