import React from "react";

function VHackathonInstance() {
  return (
    <iframe
      title="VHackathon ETH Turin"
      src="https://ethturin.com"
      style={{
        height: `100%`,
        width: `100%`,
        border: "0px",
      }}
    ></iframe>
  );
}

export default VHackathonInstance;
