import React from "react"

function HelpInstance() {
	return (
		<iframe
			title="Help"
			src="https://interspacechat.github.io/interspace.chat/help"
			style={{
				height: `100%`,
				width: `100%`,
				border: "0px",
			}}></iframe>
	)
}

export default HelpInstance
